import React from 'react';

export default class Tool extends React.Component {
    render() {
        return ele;
    };
}

const ele = (
    <section className="resume-section p-3 p-lg-5 d-flex justify-content-center">
        <h1>Coming Soon</h1>
    </section>
);